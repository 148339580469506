import React, { useState, useEffect, useMemo } from 'react'
import { pdfjs, Page } from 'react-pdf'
import { Document } from 'react-pdf/dist/esm/entry.webpack'

import {
    Box,
    Button,
    Fade,
    Modal,
    Paper,
    Typography,
    makeStyles,
} from '@material-ui/core'

//import { REACT_APP_API_URL } from 'src/actions/openapi'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const useStyles = makeStyles((theme) => {
    return {
        root: {
            backgroundColor: 'white',
        },
        modal: {
            maxWidth: '900px',
            height: `calc(825px + ${theme.spacing(6)}px + ${theme.spacing(
                4
            )}px)`,
            margin: `${theme.spacing(2)}px auto`,
            display: 'flex',
            justifyContent: 'center',
        },
        modalContent: {
            backgroundColor: theme.palette.background.default,
            height: '95vh',
            width: '95vw',
        },
        pdfHeader: {
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            height: theme.spacing(6),
            backgroundColor: theme.palette.background.default,
            border: '1px solid #E0E0E0',
            zIndex: '1',
        },
        pdfHeaderButton: {},
        pdfPageWrapper: {
            marginBottom: theme.spacing(3),
        },
        pdfContainer: {
            padding: `${theme.spacing(2)}px`,
            height: '80vh',
            overflow: 'scroll',
        },
    }
})

export default function PdfViewer({
    signedS3Link,
    assetName,
    onClose,
    active,
}) {
    const classes = useStyles()
    const [numPages, setNumPages] = useState(null)
    const [pdfPages, setPDFPages] = useState([])

    useEffect(() => {
        const pdfPagesTemp = []
        for (let i = 1; i <= numPages; i++) {
            pdfPagesTemp.push(
                <Paper className={classes.pdfPageWrapper} key={`pdfPage-${i}`}>
                    <Page
                        className={classes.pdfPage}
                        pageNumber={i}
                        key={`${i}-pdfPage`}
                        scale={1.4}
                    />
                </Paper>
            )
        }
        setPDFPages(pdfPagesTemp)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numPages])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages)
    }
    const file = useMemo(() => ({ url: signedS3Link }), [signedS3Link])
    return (
        <Modal className={classes.modal} open={active} closeAfterTransition>
            <Fade in={active} timeout={500}>
                <Box
                    style={{ outline: 'none' }}
                    className={classes.modalContent}
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        className={classes.pdfHeader}
                        alignItems="center"
                        padding="5px"
                        boxShadow={2}
                    >
                        <Typography variant="h4" color="textPrimary">
                            {assetName}
                        </Typography>
                        <Button
                            className={classes.pdfHeaderButton}
                            color="primary"
                            onClick={() => {
                                onClose(null)
                            }}
                            variant="contained"
                            size="large"
                        >
                            Close
                        </Button>
                    </Box>
                    <Box className={classes.pdfContainer}>
                        <Document
                            file={file}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {pdfPages}
                        </Document>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}
