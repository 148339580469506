import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import { Box, makeStyles, Button } from '@material-ui/core'
import { Height } from '@material-ui/icons'

function applyBoldStyle(inputText, classes) {
    return (
        <Box className={classes.boldText} display="inline">
            {inputText}
        </Box>
    )
}

const isPlural = (count, singular) => {
    const plural = `${singular}s`
    return `${Math.abs(count)} ${count === 1 ? singular : plural}`
}

const useStyles = makeStyles((theme) => ({
    root: {},
    categoryButton: {
        backgroundColor: `transparent`,
        padding: '1px 5px',
        margin: '0 2px 2px',
        borderRadius: '2px',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.05)',
            transition: 'transform 0.2s ease-in-out',
        },
    },
    findingButton: {
        boxShadow: `0 0 0.5px 1px rgba(${theme.palette.primary.medium})`,
        color: theme.palette.text.lightdark,
        '&:hover': {
            backgroundColor: `rgba(${theme.palette.primary.medium})`,
            color: theme.palette.text.darklight,
        },
    },
    escalatedButton: {
        boxShadow: `0 0 0.5px 1px rgba(${theme.palette.text.orange},1)`,
        color: theme.palette.text.lightdark,
        '&:hover': {
            backgroundColor: `rgba(${theme.palette.text.orange}, .8)`,
            color: theme.palette.text.darklight,
        },
    },
    outcomeButton: {
        boxShadow: `0 0 0.5px 1px rgba(${theme.palette.text.red},1)`,
        color: theme.palette.text.lightdark,
        '&:hover': {
            backgroundColor: `rgba(${theme.palette.text.red}, .8)`,
            color: theme.palette.text.darklight,
        },
    },
    boldText: {
        fontWeight: theme.typography.fontWeightBold,
    },
    cardText: {
        color: theme.palette.background.primary,
        fontSize: '14px',
        lineHeight: '25px',
        textAlign: 'center',
        letterSpacing: '-0.2px',
    },
    buttonIcon: {
        marginLeft: theme.spacing(0.2),
        marginTop: theme.spacing(-0.1),
    },
}))

const CategoryCardText = ({
    findingsCount = 0,
    escalatedCount = 0,
    outcomesCount = 0,
    category,
    categoryUrlParams = '',
    signalUrlParams = '',
}) => {
    const classes = useStyles()

    const isPopulatedCard =
        findingsCount > 0 || escalatedCount > 0 || outcomesCount > 0

    const categoryLabels = {
        Attack: 'finding',
        Detect: 'case',
        default: 'vuln',
    }

    const getCategoryLabel = (category) =>
        categoryLabels[category] || categoryLabels.default

    return (
        <Box className={classes.cardText}>
            {isPopulatedCard ? (
                <Fragment>
                    This service generated
                    <br />
                    <Button
                        variant="contained"
                        size="small"
                        className={`${classes.findingButton} ${classes.categoryButton}`}
                        component={RouterLink}
                        to={`/signals?${signalUrlParams}`}
                    >
                        {applyBoldStyle(
                            isPlural(findingsCount, 'signal'),
                            classes
                        )}
                        <NavigateNextIcon className={classes.buttonIcon} />
                    </Button>
                    <br />
                    Resulting in
                    <br />
                    <Button
                        variant="contained"
                        size="small"
                        className={`${classes.escalatedButton} ${classes.categoryButton}`}
                        component={RouterLink}
                        to={`/signals?${categoryUrlParams}`}
                    >
                        {applyBoldStyle(
                            isPlural(
                                escalatedCount,
                                getCategoryLabel(category)
                            ),
                            classes
                        )}
                        <NavigateNextIcon className={classes.buttonIcon} />
                    </Button>
                    <br />
                    And
                    <br />
                    <Button
                        variant="contained"
                        size="small"
                        className={`${classes.outcomeButton} ${classes.categoryButton}`}
                        component={RouterLink}
                        to={`/signals?${signalUrlParams}&outcome=true`}
                    >
                        {applyBoldStyle(
                            isPlural(outcomesCount, 'outcome'),
                            classes
                        )}
                        <NavigateNextIcon className={classes.buttonIcon} />
                    </Button>
                </Fragment>
            ) : (
                <Fragment>
                    This card will be updated with any applicable{' '}
                    {getCategoryLabel(category)}s.
                </Fragment>
            )}
        </Box>
    )
}

CategoryCardText.propTypes = {
    findingsCount: PropTypes.number,
    escalatedCount: PropTypes.number,
    outcomesCount: PropTypes.number,
    category: PropTypes.string.isRequired,
    categoryUrlParams: PropTypes.string,
    signalUrlParams: PropTypes.string,
}

export default CategoryCardText
